import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';
import { FormGroup, Validators } from '@angular/forms';

import { User } from '@/models/user';
import { Email } from '@/models/email';

import { UserService } from '@/services/user/user.service';
import { EmployeesService } from '@/services/employees/employees.service';
import { AuthService } from '@/services/auth/auth.service';
import { AlertService } from '@/services/alert/alert.service';
import { FilesService } from '@/services/files/files.service';
import { EmailService } from '@/services/email/email.service';
import { CommentsService } from '@/services/comments/comments.service';
import { SMSService } from '@/services/sms/sms.service';
import { ActivityLogService } from '@/services/activity-log/activity-log.service';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import html2pdf from 'html2pdf.js'
import { isArray } from 'util';

@Injectable({
	providedIn: 'root'
})
export class JobsService {
	emailJobData: Email = new Email()
	currentUser: User
	attachments: Array<object> = []
	fromEmail: string = 'notifications@sterlingroofingfl.com'
	fromName: string = 'Sterling Roofing'
	emailJobFormError: string = ''
	pdfGenerating: Boolean = false
	pdfComponents: any
	job: any
	jobType: any
	sendType: any
	jobDataElement: any
	pdfModalElement: any
	textRecipients: string[]
	emailRecipients: string[]
	emailSent: Boolean = false
	textSent: Boolean = false
	modalReference: NgbModalRef

	constructor(
		private http: HttpClient,
		private employeeService: EmployeesService,
		private userService: UserService,
		private commentService: CommentsService,
		private authService: AuthService,
		private alertService: AlertService,
		private activityLogService: ActivityLogService,
		private filesService: FilesService,
		private emailService: EmailService,
		private smsService: SMSService,
		private modalService: NgbModal,
		@Inject(APP_CONFIG) private config: AppConfig
	) {
		this.authService.currentUser.subscribe(user => this.currentUser = user);
	}

	private handleError(error: any, context: any = '') {
		console.error('Invalid Request')

		let message = `Failure: ${error}`;

		// if( context ) {
		// 	message = `${message} ${context}`;
		// }

		// return an observable with a user-facing error message
		return throwError(message);
	}

	validateCC(CC: string) {
		let allCCs = CC.split(',')
		allCCs.forEach(cc => {
			const emailTrimmed = cc.trim()
			if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailTrimmed)) {
				this.emailJobData.cc.push({ email: emailTrimmed, name: '' })
				this.emailJobFormError = ''
			}
			else {
				this.alertService.error(`The CC email: ${emailTrimmed} is invalid.`)
				this.emailJobFormError = 'true'
				return false
			}
		})
	}

	public sendJobDetails(data, formValues, jobDataElement, job, pdfComponents, pdfModalElement, sendType, jobType) {
		let { value } = formValues,
			{ CC, EmailSubject, Message, Attachments }: { CC: string, EmailSubject: string, Message: string, Attachments: Array<string> } = value,
			pdfOptions = {}
		const { EmailRecipients } = data
		const { TextRecipients } = data

		this.job = job
		this.jobDataElement = jobDataElement
		this.pdfComponents = pdfComponents
		this.pdfModalElement = pdfModalElement
		this.jobType = jobType
		this.sendType = sendType

		if (this.emailJobFormError.length) {
			return
		}

		if (data.IncludePOItems) {
			pdfOptions['includePOItems'] = true
		}

		if (data.IncludeComments) {
			pdfOptions['includeComments'] = true
		}

		if (data.IncludeActivityLog) {
			pdfOptions['includeActivityLog'] = true
		}

		if (data.IncludePaymentDetails) {
			pdfOptions['includePaymentDetails'] = true
		}

		pdfOptions['saveFile'] = true

		// Set Email information
		this.emailJobData = new Email()
		this.emailJobData.subject = EmailSubject
		this.emailJobData.body = Message
		this.emailJobData.type = 'text'
		this.emailJobData.relatedObjectId = this.job.ID
		this.emailJobData.from.email = this.fromEmail
		this.emailJobData.from.name = this.fromName
		this.emailJobData.attachmentFiles = Attachments

		if (this.sendType === 'Email Only') {
			// Set the From address as the current user
			this.userService.getUser(this.currentUser.userID).subscribe((resFrom: any) => {
				let { Email: email, FullName: name } = resFrom.body
				if (name === null) {
					name = ''
				}

				this.emailJobData.replyTo.email = email
				this.emailJobData.replyTo.name = name

				// set the To address
				if (EmailRecipients) {
					this.getMultipleEmployees(EmailRecipients).subscribe(employees => {
						this.emailRecipients = employees.map(employee => employee.Email);
						for (var recipient in this.emailRecipients) {
							this.emailJobData.to.push({ email: this.emailRecipients[recipient], name: '' })
						}
					})
					// console.log('this.emailRecipients', this.emailRecipients);
					// for(var recipient in this.emailRecipients){
					// 	this.emailJobData.to.push({ email: this.emailRecipients[recipient], name: '' })
					// }
				}
				else {
					this.alertService.error('You must specify an email recipient', false);
					this.emailJobFormError = 'true'
					return
				}
				// validate each CC address, then set them
				if (CC.length) {
					this.validateCC(CC)
				}
				// generate PDF
				this.generatePDF(pdfOptions, this.sendType)
			})
		}
		else if (this.sendType === 'Text Only') {
			if (TextRecipients) {
				this.getMultipleEmployees(TextRecipients).subscribe(employees => {
					this.textRecipients = employees.map(employee => employee.CellPhone)
					this.generatePDF(pdfOptions, this.sendType)
				})
			}
			else {
				this.alertService.error('You must specify a Text/SMS recipient', false);
				this.emailJobFormError = 'true'
				return
			}
		}
		else {
			this.userService.getUser(this.currentUser.userID).subscribe((resFrom: any) => {
				let { Email: email, FullName: name } = resFrom.body
				if (name === null) {
					name = ''
				}

				this.emailJobData.replyTo.email = email
				this.emailJobData.replyTo.name = name

				// set the To address
				if (TextRecipients) {
					this.getMultipleEmployees(TextRecipients).subscribe(employees => {
						this.textRecipients = employees.map(employee => employee.CellPhone)
					})
				}
				else {
					this.alertService.error('You must specify a Text/SMS recipient', false);
					this.emailJobFormError = 'true'
					return
				}
				if (EmailRecipients) {
					this.getMultipleEmployees(EmailRecipients).subscribe(employees => {
						this.emailRecipients = employees.map(employee => employee.Email);
						for (var recipient in this.emailRecipients) {
							this.emailJobData.to.push({ email: this.emailRecipients[recipient], name: '' })
						}
					})
				}
				else {
					this.alertService.error('You must specify an email recipient', false);
					this.emailJobFormError = 'true'
					return
				}
				// validate each CC address, then set them
				if (CC.length) {
					this.validateCC(CC)
				}
				// generate PDF
				this.generatePDF(pdfOptions, this.sendType)
			})
		}
	}

	dissmisJobModal() {
		const pdfModal = document.getElementById('pdf-modal')
		if (this.sendType === 'Email and Text') {
			if (this.textSent && this.emailSent) {
				pdfModal.innerHTML = ''
				this.modalReference.close()
			}
		}
		else {
			pdfModal.innerHTML = ''
			this.modalReference.close()
		}
	}

	generatePDF(pdfOptions: any, sendType: string) {
		const jobInfo = this.jobDataElement.nativeElement.querySelector('.c-job_info').cloneNode(true)
		let headerTitle: string, pdfFileName: string

		const communityName = this.job.Community ? this.job.Community.Name : 'Community'
		const crewLeaderName = this.job.CrewLeader ? this.job.CrewLeader.FullName : 'Crew Leader'

		switch (this.jobType) {
			case 'Reroof':
			case 'Repair':
				headerTitle = pdfFileName = `${this.jobType} Job - ${this.job.FirstName} ${this.job.LastName}`
				break
			case 'BuilderJob':
			case 'Warranty':
				headerTitle = pdfFileName = `${this.jobType} - ${communityName} - ${this.job.Lot1} ${this.job.Lot2} - ${crewLeaderName}`
				break
		}

		this.pdfGenerating = true
		let pdfGenerationData: any = {
			headerTitle: headerTitle,
			jobInfo: jobInfo,
			poItems: null,
			payments: null,
			comments: null,
			activityLog: null,
		}
		let paymentsObj = null

		// Add Job Payment Details if they exist
		if (this.job.PaymentAmount || this.job.PaymentID || this.job.PaymentDate) {
			paymentsObj = {
				PaymentAmount: this.job.PaymentAmount,
				PaymentDate: this.job.PaymentDate,
				PaymentID: this.job.PaymentID,
			}
		}

		pdfGenerationData.activityLog = pdfOptions.includeActivityLog ? this.pdfComponents.activityLog : null
		pdfGenerationData.poItems = pdfOptions.includePOItems ? this.pdfComponents.poForm : null
		pdfGenerationData.payments = pdfOptions.includePaymentDetails && paymentsObj ? paymentsObj : null
		pdfGenerationData.comments = pdfOptions.includeComments && this.pdfComponents.comments['data'].length ? this.pdfComponents.comments['data'] : null

		const pdfHtml = this.getJobPdf(pdfGenerationData)

		this.openModal(this.pdfModalElement, 'c-pdf-data-modal')
		let pdfModal = document.getElementById('pdf-modal')

		pdfModal.innerHTML += pdfHtml + pdfModal.innerHTML

		const pdfOpts = {
			filename: pdfFileName,
			pagebreak: {
				mode: 'css',
				before: '.break-before',
				after: '.break-after',
			}
		}

		if (pdfOptions.saveFile) {
			html2pdf().set(pdfOpts).from(pdfModal).outputPdf('blob').then(result => {
				if (sendType === 'Text Only') {
					this.textPdfFile(result)
				}
				else if (sendType === 'Email Only') {
					this.emailPdfFile(result)
				}
				else {
					this.textPdfFile(result)
					this.emailPdfFile(result)
				}
			});
		}
		else {
			html2pdf().set(pdfOpts).from(pdfModal).save().then(res => {
				this.pdfGenerating = false
				pdfModal.innerHTML = ''
				this.dissmisJobModal()
			})
		}
	}

	blobToFile(theBlob: Blob, fileName: string): File {
		return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
	}

	textPdfFile(pdf): void {
		const pdfFile = this.blobToFile(pdf, `${this.jobType}${this.job.ID}.pdf`)
		let textData = {
			recipients: this.textRecipients,
			body: '',
			relatedObjectId: this.job.ID
		}

		// upload new pdf file to get file ID before texting
		this.filesService.uploadFile(pdfFile, '00000000-0000-0000-0000-000000000000', this.jobType, true).subscribe(
			res => {
				let pdfFileID
				if (isArray(res)) {
					pdfFileID = res[0]
					// Construct text message body with downloadable file URL
					textData.body = `${this.jobType} details requested: ${this.config.apiUrl}/File/sms/${pdfFileID}`
					// Send text
					this.smsService.sendSMS(textData).subscribe((data: any) => {
						this.alertService.success(`Message sent successfully!`, false);
						this.textSent = true
						this.dissmisJobModal();
						this.activityLogService.addLogEntry(this.jobType, this.job.ID, 'Job details sent via SMS to ' + textData.recipients.join(', '));
					},
						error => {
							this.alertService.error('There was an error sending your message. Please try again.', false);
							document.body.scrollIntoView({ behavior: "smooth" });
						})
				}
			})
	}

	emailPdfFile(pdf): void {
		const pdfFile = this.blobToFile(pdf, `${this.jobType}-${this.job.ID}.pdf`)

		// upload new pdf file to get file ID before emailing
		this.filesService.uploadFile(pdfFile, '00000000-0000-0000-0000-000000000000', this.jobType).subscribe(
			res => {
				let pdfFileID
				if (isArray(res)) {
					pdfFileID = res[0]
					// Add newly uploaded PDF file to email attachments array
					this.emailJobData.attachmentFiles.unshift(pdfFileID)

					this.emailJobData.relatedObjectId = this.job.ID

					// Send email
					this.emailService.sendEmail(this.emailJobData).subscribe((data: any) => {
						this.alertService.success(`Message sent successfully!`, false);
						this.emailSent = true
						this.dissmisJobModal()
						this.activityLogService.addLogEntry(this.jobType, this.job.ID, 'Job details sent via Email to ' + this.emailRecipients.join(', '));
					},
						error => {
							this.alertService.error('There was an error sending your message. Please try again.', false);
							document.body.scrollIntoView({ behavior: "smooth" });
						})
				}
			})
	}

	openModal(modal, windowClass) {
		this.modalReference = this.modalService.open(modal, { ariaLabelledBy: "modal-basic-title", windowClass })
		this.modalReference.result.then(
			result => {
				`Closed with ${result}`
			},
			reason => {
				`Dismissed with ${reason}`
			}
		)
	}

	// Get multiple employees at the same time
	public getMultipleEmployees(recipients): Observable<any[]> {
		let responses = recipients.map(recipient => {
			return this.http.get(`${this.config.apiUrl}/Employees/get/${recipient}`)
		})

		return forkJoin(responses)
	}

	public textEmailOptions(form: FormGroup, value: string) {
		switch (value) {
			case 'Text Only':
				form.get('TextRecipients').setValidators([Validators.required])
				form.get('TextRecipients').updateValueAndValidity()
				form.get('EmailRecipients').clearValidators()
				form.get('EmailRecipients').updateValueAndValidity()
				break;

			case 'Email Only':
				form.get('TextRecipients').clearValidators()
				form.get('TextRecipients').updateValueAndValidity()
				form.get('EmailRecipients').setValidators([Validators.required])
				form.get('EmailRecipients').updateValueAndValidity()
				break;

			case 'Email and Text':
				form.get('TextRecipients').setValidators([Validators.required])
				form.get('TextRecipients').updateValueAndValidity()
				form.get('EmailRecipients').setValidators([Validators.required])
				form.get('EmailRecipients').updateValueAndValidity()
				break;
		}
	}

	public getJobPdf(pdfOptions: any) {
		let pdfHtml: string = ''
		let paymentsHtml: string = ''
		let poItemsHtml: string = ''
		let breakClass: string = ''
		const { headerTitle, payments, poItems, jobInfo, comments, activityLog, jobType } = pdfOptions

		// Remove pencil edit icons
		const pencils = jobInfo.getElementsByClassName('fa-pencil')
		for (const pencil of pencils) {
			pencil.style.display = 'none'
		}

		// PDF Header
		pdfHtml += `
			<div id="modal-header" class="modal-header" style="width:800px;justify-content:flex-start">
				<img
					class="c-logo_image"
					src="assets/images/sterling-logo.png"
					alt="Sterling Roofing"
					width="60"
					height="60"
					style="margin-right:20px"
				>
				<h4 id="modal-title" class="modal-title">${headerTitle}</h4>
			</div>
			<div class="modal-body" style="width:800px">
				<!-- Top Wrapper Start -->
				<div class="c-pdf_top">
					<h2 class="c-job_section-title">Job Information</h2>
					${jobInfo.outerHTML}
		`

		if (payments) {
			const jobPayments = this.buildPaymentsObject(payments)
			paymentsHtml += `
				<div id="job-payment-data" class="c-job_section row">
					<div class="c-job_section-header col-12">
						<div class="row justify-space-between">
							<h2 class="c-job_section-title col">
								Payment Details
							</h2>
						</div>
					</div>
			`
			Object.keys(jobPayments).forEach((key: any) => {
				paymentsHtml += `
					<div class="form-group col-12 col-sm-6 col-md-4">
						<div class="c-job-data">
							<div class="c-job-data_content">
								<div class="c-job-data_label">
									${jobPayments[key].title}
								</div>
								<div class="c-job-data_value">
									${jobPayments[key].data}
								</div>
							</div>
						</div>
					</div>
				`
			})
			// Close #job-payment-data
			paymentsHtml += '</div>'
			pdfHtml += paymentsHtml
		}

		// Top Wrapper Close
		pdfHtml += '</div> <!-- End Top Wrapper -->'

		let poStyle: string = ''
		if (!payments) {
			poStyle = 'margin-top:160px'
		}

		if (poItems) {
			let poOptionsHtml: string = ''
			poItemsHtml += `
				<div class="c-job_section row${breakClass}" style="${poStyle}">
					<div class="col-12">
						<table class="table c-table--po-items" style="table-layout:auto:margin-bottom:0;margin-top:0;width:100%;">
							<thead style="width:800px;border-bottom:1px solid #CCC">
								<th style="border-bottom:0">PO Item</th>
								<th style="border-bottom:0;padding-left:10px">Short</th>
								<th style="border-bottom:0">Quantity</th>
								<th style="border-bottom:0">Actual</th>
								<th style="border-bottom:0">Unit</th>
								<th style="border-bottom:0">Options</th>
							</thead>
							<tbody>
			`

			for (const [key, value] of Object.entries(poItems)) {
				if (key !== 'PO' && key !== 'PermitStatus') {
					if (typeof value !== 'boolean') {
						poItemsHtml += `
							<tr>
								<td style="padding:5px">${key}</td>
								<td style="padding:5px;padding-left:10px">${value['Short'] ? 'Yes' : 'No'}</td>
								<td style="padding:5px">${value['Quantity'] ? value['Quantity'] : 0}</td>
								<td style="padding:5px">${value['Actual'] ? value['Actual'] : 0}</td>
								<td style="padding:5px">${value['Unit'] ? value['Unit'] : 0}</td>
						`

						if (value['Options']) {
							const optionsArray = Object.keys(value['Options'])
							optionsArray.forEach((element, index) => {
								poItemsHtml += `
									<td style="padding:0"><strong>${element}:</strong> ${value['Options'][element]}</td>
								`
								if (index !== optionsArray.length - 1) {
									poItemsHtml += '<br>'
								}
							})
						}

						poItemsHtml += `
							</tr>
						`
					}
					else {
						poOptionsHtml += `
							<span style="width:33.33333%;float:left"><strong>${key}: </strong>&nbsp;${value ? ' Yes' : ' No'}</span>
						`
					}
				}
			}

			poItemsHtml += `
							</tbody>
						</table>
					</div>
				</div>
				<div>${poOptionsHtml}</div>
			`
			pdfHtml += poItemsHtml
		}

		if (comments) {
			// Set margin-top based on which modules are present above the comments
			let mt = 0

			if (jobType === 'builder') {
				if (poItems) {
					mt += 370
				}
				else {
					if (payments) {
						mt += 280
					}
					else {
						mt += 420
					}
				}
			}

			let commentsHtml: string = `
				<div class="c-job_section row mb-5" style="margin-top:${mt}px">
					<h2 class="c-job_section-title col-12">Comments</h2>
			`

			comments.forEach((comment: any, index) => {
				const date = new Date(comment.LastModifiedDate).toLocaleDateString()
				const time = new Date(comment.LastModifiedDate).toLocaleTimeString()
				comment.CleanCommentText = this.commentService.generateCleanCommentText(comment.CommentText)
				commentsHtml += `
					<div class="c-comment col-12">
						<div class="c-comment_header row">
							<strong class="c-comment_user col-6">${comment.User.FullName || comment.User.Email}</strong>
							<span class="col-6 text-right">
								<span class="c-comment_date">${date}, ${time}</span>
							</span>
						</div>
						<div>${comment.CleanCommentText}</div>
					</div>
				`
				if (index !== comments.length - 1) {
					commentsHtml += '<div class="col-12"><hr style="margin-bottom:10px;border-color:#CCC"></div>'
				}
			})

			commentsHtml += '</div>'

			pdfHtml += commentsHtml
		}

		if (activityLog) {
			// Set margin-top based on which modules are present above the activity log
			let mt = 0

			if (jobType === 'builder') {
				if (!poItems && !comments) {
					if (payments) {
						mt += 280
					}
					else {
						mt += 420
					}
				}
			}

			let activityHtml: string = `
				<div class="c-job_section row" style="margin-top:${mt}px;">
					<h2 class="c-job_section-title col-12">Activity Log</h2>
					<div class="c-activity-log col-12">
						<div class="c-activity-log_inner">
						`

			activityLog.forEach((item: any, index) => {
				const bgColor = index % 2 !== 0 ? 'background-color:#E8E8E8' : ''
				activityHtml += `

							<p class="c-activity-log_list" style="${bgColor}">
								${item.Description}
							</p>
				`
			})

			activityHtml += `
						</div>
					</div>
				</div>
			`

			pdfHtml += activityHtml
		}

		// Close .modal-body
		pdfHtml += '</div>'
		pdfHtml += `
			<div class="c-loader d-flex">
				<div class="c-loader_content">
					<img
						src="assets/images/interwind-1s-200.svg"
						alt="Loading Page"
					>
					<h4 class="text-center">Generating PDF...</h4>
				</div>
			</div>
		`

		return pdfHtml
	}

	public buildPaymentsObject(payments: any) {
		let paymentsObj = {}
		if (payments.PaymentAmount) {
			paymentsObj = {
				...paymentsObj,
				amount: {
					title: 'Payment Amount',
					data: payments.PaymentAmount
				}
			}
		}
		if (payments.PaymentDate) {
			paymentsObj = {
				...paymentsObj,
				date: {
					title: 'Payment Date',
					data: new Date(payments.PaymentDate).toLocaleDateString(),
				}
			}
		}
		if (payments.PaymentID) {
			paymentsObj = {
				...paymentsObj,
				id: {
					title: 'Payment ID',
					data: payments.PaymentID
				}
			}
		}
		return paymentsObj
	}

	// TODO: Set use to power getJob requests for all job types
	public getJobs(endpoint = '', status = 'all') {
		let url = `${this.config.apiUrl}/${endpoint}/all`;

		if (status !== 'all') {
			url = `${this.config.apiUrl}/${endpoint}/list?status=${status}`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}


	// Builder Jobs
	////////////////////////////////////////////
	public getBuilderJobs(status = 'all', count = 10, offset = 0, includeCalendarNames = false) {
		let endpoint = 'builderjobs',
			url = `${this.config.apiUrl}/${endpoint}/all?count=${count}&offset=${offset}`;

		if (status !== 'all') {
			url = `${this.config.apiUrl}/${endpoint}/list?status=${status}&count=${count}&offset=${offset}`
		}

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getMyBuilderJobs(count = 10, offset = 0, includeCalendarNames = false) {
		let endpoint = 'builderjobs',
			url = `${this.config.apiUrl}/${endpoint}/mine?count=${count}&offset=${offset}`;

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getBuilderJobsDatatable(data) {
		let endpoint = 'builderjobs',
			url = `${this.config.apiUrl}/${endpoint}/datatable`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public getBuilderJob(id: string) {
		let endpoint = 'builderjobs',
			url = `${this.config.apiUrl}/${endpoint}/get?id=${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createBuilderJob(submission: any) {
		let endpoint = 'builderjobs',
			url = `${this.config.apiUrl}/${endpoint}/create`,
			data = {
				Status: 'Active',
				PermitStatus: 'Unfiled',
				Contacts: {
					Supervisor: '',
					CrewLeader: ''
				},
			};

		for (let key in submission) {
			var control = submission[key],
				value = control.value;

			if (key === 'PaymentDate'
				|| key === 'ScheduledDate'
				|| key === 'DryInStartDate'
				|| key === 'ShingleStartDate'
				|| key === 'StuccoStopStartDate'
				|| key === 'MetalStartDate') {
				if (value) {
					value = `${value.year}-${value.month}-${value.day}`;
				}
			}

			data[key] = value;
		}

		return this.http.post(url, data)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submisision and try again.'))
			);
	}

	public updateBuilderJob(id, data: any) {
		let endpoint = 'builderjobs',
			url = `${this.config.apiUrl}/${endpoint}/update/${id}`;

		if ('Status' in data) {
			if (data['Status'] == null) {
				data['Status'] = 'Active';
			}
		}
		else {
			data['Status'] = 'Active';
		}

		// console.log(data)

		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

 	// public getSearchBuilderJobsUrl() {
	// 	let endpoint = 'builderjobs',
	// 			url      = `${this.config.apiUrl}/${endpoint}/search`;
	// 	return url;
	// }

 	public searchBuilderJobs(params) {
		let endpoint = 'builderjobs',
				url      = `${this.config.apiUrl}/${endpoint}/search`;

		console.log('searchBuilderJobs', url, params);

		return this.http.post(url, params)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submisision and try again.'))
			);
	}

	// Repair Jobs
	////////////////////////////////////////////

	public getRepairJobs(status = 'all', count = 10, offset = 0, includeCalendarNames = false) {
		let endpoint = 'repairs',
			url = `${this.config.apiUrl}/${endpoint}/all?count=${count}&offset=${offset}`;

		if (status !== 'all') {
			url = `${this.config.apiUrl}/${endpoint}/list?status=${status}`;
		}

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getMyRepairJobs(count = 10, offset = 0, includeCalendarNames = false) {
		let endpoint = 'repairs',
			url = `${this.config.apiUrl}/${endpoint}/mine?count=${count}&offset=${offset}`;

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getRepairJob(id: string) {
		let endpoint = 'repairs',
			url = `${this.config.apiUrl}/${endpoint}/get?id=${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createRepairJob(submission: any) {
		let endpoint = 'repairs',
			url = `${this.config.apiUrl}/${endpoint}/create`,
			data = {
				status: "Estimated"
			};

		for (let key in submission) {
			var control = submission[key],
				value = control.value;

			if (key === 'ScheduledDate' || key === 'CallDate' || key === 'ScheduledDateEnd') {
				if (value) {
					value = `${value.year}-${value.month}-${value.day}`;
				}
			}

			data[key] = value;
		}

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public updateRepairJob(id, data: any) {
		let endpoint = 'repairs',
			url = `${this.config.apiUrl}/${endpoint}/update?id=${id}`;

		if ('Status' in data) {
			if (data['Status'] == null) {
				data['Status'] = 'Estimated';
			}
		}
		else {
			data['Status'] = 'Estimated';
		}

		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	// Reroof Jobs
	////////////////////////////////////////////

	public getReroofJobs(status = 'all', count = 5000, offset = 0, includeCalendarNames = false) {
		let endpoint = 'reroofs',
			url = `${this.config.apiUrl}/${endpoint}/all?count=${count}&offset=${offset}`;

		if (status !== 'all') {
			url = `${this.config.apiUrl}/${endpoint}/list?status=${status}`;
		}

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getMyReroofJobs(count = 10, offset = 0, includeCalendarNames = false) {
		let endpoint = 'reroofs',
			url = `${this.config.apiUrl}/${endpoint}/mine?count=${count}&offset=${offset}`;

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getReroofJob(id: string) {
		let endpoint = 'reroofs',
			url = `${this.config.apiUrl}/${endpoint}/get?id=${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createReroofJob(submission: any) {
		let endpoint = 'reroofs',
			url = `${this.config.apiUrl}/${endpoint}/create`,
			data = {
				status: "Estimated"
			};

		for (let key in submission) {
			var control = submission[key],
				value = control.value;

			if (key === 'ScheduledDate' || key === 'CallDate' || key === 'ScheduledDateEnd') {
				if (value) {
					value = `${value.year}-${value.month}-${value.day}`;
				}
			}

			data[key] = value;
		}

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public updateReroofJob(id, data: any) {
		let endpoint = 'reroofs',
			url = `${this.config.apiUrl}/${endpoint}/update?id=${id}`;

		if ('Status' in data) {
			if (data['Status'] == null) {
				data['Status'] = 'Estimated';
			}
		}
		else {
			data['Status'] = 'Estimated';
		}

		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	// Warranty Jobs
	////////////////////////////////////////////

	public getWarrantyJobs(status = 'all', count = 2000, offset = 0, includeCalendarNames = false) {
		let endpoint = 'warranties',
			url = `${this.config.apiUrl}/${endpoint}/all?count=${count}&offset=${offset}`;

		if (status !== 'all') {
			url = `${this.config.apiUrl}/${endpoint}/list?status=${status}`;
		}

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getWarrantyJobsDatatable(data) {
		let endpoint = 'warranties',
			url = `${this.config.apiUrl}/${endpoint}/datatable`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public getReroofJobsDatatable(data) {
		let endpoint = 'reroofs',
			url = `${this.config.apiUrl}/${endpoint}/datatable`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public getRepairJobsDatatable(data) {
		let endpoint = 'repairs',
			url = `${this.config.apiUrl}/${endpoint}/datatable`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public getMyWarrantyJobs(count = 10, offset = 0, includeCalendarNames = false) {
		let endpoint = 'warranties',
			url = `${this.config.apiUrl}/${endpoint}/mine?count=${count}&offset=${offset}`;

		if (includeCalendarNames) {
			url = `${url}&includeCalendarNames=true`
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getWarrantyJob(id: string) {
		let endpoint = 'warranties',
			url = `${this.config.apiUrl}/${endpoint}/get?id=${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createWarrantyJob(submission: any) {
		let endpoint = 'warranties',
			url = `${this.config.apiUrl}/${endpoint}/create`,
			data = {
				status: "Estimated"
			};

		for (let key in submission) {
			var control = submission[key],
				value = control.value;

			if (key === 'ScheduledDate' || key === 'WorkOrderReceivedDate' || key === 'ScheduledDateEnd') {
				if (value) {
					value = `${value.year}-${value.month}-${value.day}`;
				}
			}

			data[key] = value;
		}

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public updateWarrantyJob(id, data: any) {
		let endpoint = 'warranties',
			url = `${this.config.apiUrl}/${endpoint}/update?id=${id}`;

		if ('Status' in data) {
			if (data['Status'] == null) {
				data['Status'] = 'Estimated';
			}
		}
		else {
			data['Status'] = 'Estimated';
		}

		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}
}
